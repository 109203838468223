import {
  client,
  CHAT_TRANSFER,
  CHAT_TRANSFER_BY_EMAIL,
} from '@guuru/graphql-web';
import { ChatAPI } from '@guuru/api-web';
import smartForm from '@guuru/smartform-web';
import { storage } from '@guuru/chat-web';

const { isChatIframedVar } = storage;

export default async function (partnerId, chatId, { handover, targetId }) {
  const isDelegated = isChatIframedVar() && (
    await smartForm.showClientContactForm(partnerId, chatId)
  );

  const {
    data: {
      transferChat,
      transferByEmail,
    },
  } = await client.mutate({
    mutation: handover ? CHAT_TRANSFER_BY_EMAIL : CHAT_TRANSFER,
    variables: { chatId, targetId },
  });

  const status = transferChat?.status ?? transferByEmail?.status;

  if (isDelegated) {
    ChatAPI.storeReset(partnerId);
  }

  return { chatId, status };
}
