import { gql } from '@apollo/client';

export default gql`
  query GetPersistedExperts($partnerId: ID!, $first: Int, $after: String) {
    community(id: $partnerId) {
      id
      experts(first: $first, after: $after) {
        edges {
          node {
            id
            bio
            photoUrl
            name
            joinedAt
            rating
          }
       }
        pageInfo {
         hasNextPage
         endCursor
        }
      }
    }
  }
`;
