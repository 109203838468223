import { put } from 'redux-saga/effects';
import { ChatAPI } from '@guuru/api-web';
import { ACCEPT_BOT_ANSWER, client } from '@guuru/graphql-web';
import { getCurrentChatId, storage, types } from '@guuru/chat-web';
import events from '@guuru/events-web';
import log from '@guuru/logger-web';

import handleChatGreeting from './handleChatGreeting';
import handleShowAnswer from './handleShowAnswer';
import handleRouting from './handleRouting';
import createAndTransferChat from '../../chatActions/createAndTransferChat';
import addMessagesNotYetOnAPI from '../../chatActions/addMessagesNotYetOnAPI';

const { isBotTypingVar } = storage;

const addUserPredictResult = (text, partnerId, options) => {
  const messageStyle = ChatAPI.getMessageStyle(partnerId);
  const message = ChatAPI.messages.createAnonymousTextMessage(
    text,
    true,
    false,
    {
      isUserPredictionAnswer: true,
      messageStyle,
      response: options?.response,
    },
  );
  ChatAPI.pushPendingMessages(message);
  return message;
};

const actionsToHandle = (channel = 'chat') => {
  const isChat = channel !== 'form';

  return {
    ...isChat && {
      greeting: handleChatGreeting,
    },
    routing: handleRouting,
    showAnswer: handleShowAnswer,
  };
};

const acceptBotAnswer = async function ({ partnerId, chatId }) {
  await addMessagesNotYetOnAPI(partnerId, chatId);
  await client.mutate({
    mutation: ACCEPT_BOT_ANSWER,
    variables: { id: chatId },
  });
};

export default {
  // if we don't have an action we don't have a prediction.
  validate(_, { skipAutomation } = {}) {
    const priority = ChatAPI.storeRetrieveChatPriority();
    return !skipAutomation && !priority;
  },

  * render(partnerId, { automationBehavior }) {
    const channel = ChatAPI.storeRetrieveChannel(partnerId);

    const action = actionsToHandle(channel)[automationBehavior.action];
    if (!action) {
      return { goToNextStep: true };
    }

    events.predict(automationBehavior.label);

    return yield action(automationBehavior, {
      channel,
      partnerId,
    });
  },

  * processResponse(
    partnerId,
    {
      text,
      response,
      smartFormStyle,
      subscriptionPriority,
      subscriptionTarget,
    },
  ) {
    try {
      const chatId = getCurrentChatId();
      ChatAPI.storeUserPredictionAnswer(partnerId, response);
      const channel = ChatAPI.storeRetrieveChannel(partnerId);
      const automationBehavior = ChatAPI.storeRetrieveAutomationBehavior(
        partnerId,
      );
      const userMessage = addUserPredictResult(
        text,
        partnerId,
        { channel, response },
      );

      yield put({
        type: types.CHAT_NEW_TEXT_SUCCESS,
        message: userMessage,
        chatId,
      });

      if (response === 'Yes') {
        isBotTypingVar(false);
        yield acceptBotAnswer({ partnerId, chatId });

        return { stayInCurrentStep: true };
      }

      if (subscriptionPriority === 'transfer' || response === 'Transfer') {
        // In order for the chat to be accepted by the bot we need to make sure
        // that the priority is transfer, as so, we will update the
        // automationBehavior accordingly
        ChatAPI.storeAutomationBehavior(partnerId, {
          ...automationBehavior,
          subscriptionPriority: 'transfer',
        });

        // In this case the user selected that he wanted a transfer
        // as so, it's not a handover (we charge)
        yield createAndTransferChat(partnerId, {
          targetId: subscriptionTarget,
        });
        isBotTypingVar(false);
        return { stayInCurrentStep: true };
      }

      ChatAPI.storeChatPriority(subscriptionPriority);

      if (smartFormStyle) {
        const talkingToExpertMessage = yield ChatAPI.addBotMessage(
          'bot:::talkToExpertSmartForm',
        );
        yield put({
          type: types.CHAT_NEW_TEXT_SUCCESS,
          message: talkingToExpertMessage,
        });
      }
      return {};
    } catch (error) {
      log.error(error);
      return {};
    }
  },
};
