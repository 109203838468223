import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import GET_STATS from './queries/getStats';
import Statistic from '../Statistic';

const TotalMembers = function () {
  const { partnerId } = useParams();

  const {
    data: {
      community: {
        stats: {
          totalChats,
        } = {},
      } = {},
    } = {},
    loading,
  } = useQuery(GET_STATS, {
    variables: { partnerId },
  });

  return (
    <Statistic title="Connections" value={totalChats} loading={loading} />
  );
};

export default TotalMembers;
