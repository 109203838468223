import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ChatView = styled.div`
  display: grid;
  overflow: hidden;
  height: 100%;

  .chat-content-section {
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    * {
      overflow-anchor: none;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      flex-direction: column;
      flex-grow: 1;
    }

    /* stylelint-disable-next-line selector-class-pattern, selector-list-comma-newline-after */
    &.mod--rate, &.mod--connecting, &.mod--finished {
      padding-bottom: 0;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.mod--fullScreen {
      padding: 0 5px;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.mod--user.mod--rate {
      margin-bottom: 0;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.mod--smartForm {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.mod--expert {
    grid-template-rows: 1fr;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.mod--user {
    grid-template-rows: ${({ theme }) => (theme.chatLayout ? 'auto auto 1fr' : 'auto auto auto 1fr')};
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.mod--user.mod--fullScreen {
    grid-template-rows: auto auto 1fr;
  }

  &:focus {
    outline: inherit;
  }

  /* stylelint-disable selector-class-pattern */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &,
    &.mod--chat,
    &.mod--user.mod--chat {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const ChatScrollAnchor = styled.div`
  &#chat-scroll-anchor {
    height: 1px;
    overflow-anchor: auto;
    background-color: ${({ theme, $setBackgroundColor }) => (
    (theme.chatLayout && $setBackgroundColor) ? theme.primaryColor : null
  )};
  }
`;
