import React from 'react';
import { Link } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Col, Flex, Typography } from 'antd';
import { useChatSettings } from '@guuru/chat-web';
import { PoweredBy } from '@guuru/react-powered-by';
import PropTypes from 'prop-types';
import events from '@guuru/events-web';

const { Title } = Typography;

const TopBar = styled.div`
  align-items: center;
  height: 64px;
  background-color: ${({ theme }) => theme.primaryColor};
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 2;

  .ant-typography {
    color: ${({ theme }) => theme.onSecondaryColor};
    margin: 0;
  }
`;

const BackArrow = styled(LeftOutlined)`
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  font-size: 20px;
  color: ${({ theme }) => theme.onSecondaryColor};
`;

const CommunityView = function ({
  title = null,
  goBackToPath = null,
  children,
}) {
  const {
    data: {
      chatSettings: {
        id: partnerId,
        name,
        chatWidgetPrimaryColor,
      },
    },
  } = useChatSettings();

  return (
    <>
      <meta name="theme-color" content={chatWidgetPrimaryColor} />
      <Flex justify="center">
        <Col xs={24} sm={24} md={20}>
          <TopBar>
            {goBackToPath && (
              <Link
                to={goBackToPath}
                onClick={() => events.communityBackButtonClicked()}
              >
                <BackArrow />
              </Link>
            )}
            <Title level={5}>{name}</Title>
          </TopBar>
        </Col>
      </Flex>
      <Flex justify="center" style={{ overflowY: 'scroll' }}>
        <Col xs={22} sm={22} md={20} style={{ paddingTop: 18 }}>
          <Flex vertical flex={1} gap={38}>
            {title && (
              <Col align="center">
                <Title level={2} style={{ margin: '16px 0 0 0' }}>
                  {title}
                </Title>
              </Col>
            )}
            {children}
          </Flex>
          <Flex justify="center">
            <PoweredBy
              url={`https://www.guuru.com/?utm_source=chat&utm_medium=chat&utm_campaign=community&utm_term=${partnerId}&utm_content=${partnerId}`}
            />
          </Flex>
        </Col>
      </Flex>
    </>
  );
};

CommunityView.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  goBackToPath: PropTypes.string,
};

export default CommunityView;
