import React from 'react';
import { Avatar } from 'antd';
import { number, string } from 'prop-types';

const ExpertAvatar = React.memo(({ url, size }) => (
  <Avatar size={size} src={url} />
));

ExpertAvatar.propTypes = {
  url: string,
  size: number,
};

ExpertAvatar.defaultProps = {
  url: null,
  size: 36,
};

export default ExpertAvatar;
