import React from 'react';
import { List } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const OpinionItem = styled(List.Item.Meta)`
  .ant-list-item-meta-avatar {
    font-size: 1.5rem;
    align-self: center;
    padding: 11px;
  }

  .ant-list-item-meta-title {
    font-weight: normal;
  }

  .ant-list-item-meta-content {
    align-self: center;
  }
`;

const ProductLink = styled(Link)`
  &:hover {
    color: #00B0FF;
  }
`;

const Opinion = function ({ mention }) {
  return (
    <List.Item>
      <OpinionItem
        avatar={<ProductLink to={mention.url} target="_blank"><LinkOutlined /></ProductLink>}
        title={mention.summary}
      />
    </List.Item>
  );
};

Opinion.propTypes = {
  mention: PropTypes.shape({
    url: PropTypes.string,
    summary: PropTypes.string,
  }).isRequired,
};

export default Opinion;
