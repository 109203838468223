import { ChatAPI } from '@guuru/api-web';
import events from '@guuru/events-web';
import { delegateToClientFormEvent } from '@guuru/chat-events-web';
import { getChat } from '@guuru/graphql-web';
import { ChannelType } from '@guuru/constants-common';

const smartForm = {
  async showClientContactForm(partnerId, chatId) {
    const { channel, question } = await getChat(chatId);
    if (channel !== ChannelType.form) {
      return false;
    }
    const {
      smartFormDelegateToClient,
      smartFormDelegateQuestionSelector,
    } = await ChatAPI.getChatSettings(partnerId);
    if (!smartFormDelegateToClient || !smartFormDelegateQuestionSelector) {
      return false;
    }
    events.delegateFormToClient();
    delegateToClientFormEvent(question);
    return true;
  },
};

export default smartForm;
