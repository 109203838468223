import { client, hotTransferChat } from '@guuru/graphql-web';
import { ChatAPI } from '@guuru/api-web';
import addMessagesNotYetOnAPI from './addMessagesNotYetOnAPI';
import createOrUpdateUser from './createOrUpdateUser';

export default async function (partnerId, chatId) {
  const categoryId = ChatAPI.storeRetrieveCategory();
  const botPriority = ChatAPI.storeRetrieveChatPriority();

  await Promise.all([
    createOrUpdateUser(partnerId),
    addMessagesNotYetOnAPI(partnerId, chatId),
  ]);

  await client.mutate({
    mutation: hotTransferChat,
    variables: {
      id: chatId,
      input: {
        categoryId,
        botPriority,
      },
    },
  });
}
