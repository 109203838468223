import React from 'react';
import Stats from '../../components/Stats';
import Experts from '../../components/Experts';
import CommunityView from '../../containers/Container';

const Community = function () {
  return (
    <CommunityView title="Meet our Community">
      <Stats />
      <Experts />
    </CommunityView>
  );
};

export default Community;
