import React from 'react';
import { withTranslation } from 'react-i18next';
import { ChatMessages, ChatRowWrapper } from '@guuru/react-chat-messages';
import { useChat } from '@guuru/chat-web';
import { useParams } from 'react-router-dom';

const Transcript = function () {
  const { chatControlToken, chatId } = useParams();
  const {
    data: {
      chat: {
        messages = {},
        languageCode,
      } = {},
    } = {},
    loading: isLoading,
  } = useChat({
    chatId,
    chatControlToken,
    useSubscriptions: false,
    isUser: false,
  });

  if (isLoading) {
    return null;
  }

  return (
    <ChatRowWrapper isUser>
      <ChatMessages
        isUser={false}
        readOnly
        chatId={chatId}
        messages={messages?.edges}
        showBotAvatar
        subscribeIsTyping={false}
        locale={languageCode}
      />
    </ChatRowWrapper>
  );
};

export default withTranslation()(Transcript);
