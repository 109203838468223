import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalChat } from '@guuru/chat-web';
import events from '@guuru/events-web';
import useMenuItems from './hooks/useMenuItems';
import { Bar } from './style';

const BottomMenu = function ({ spaces = [] }) {
  const { isStepBeforeQuestion } = useLocalChat();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { mainMenuItems, selectedKey } = useMenuItems(spaces);

  const handleClick = useCallback(({ key }) => {
    if (key === 'guurus') {
      events.communityIconClicked();
    }
    navigate(`${key}${search}`, { replace: true });
  }, [navigate, search]);

  return (
    <Bar
      onClick={handleClick}
      $showNavigation={isStepBeforeQuestion}
      selectedKeys={selectedKey}
      mode="horizontal"
      items={mainMenuItems}
    />
  );
};

BottomMenu.propTypes = {
  spaces: PropTypes.arrayOf(PropTypes.string),
};

export default BottomMenu;
