import React, { useEffect } from 'react';
import events from '@guuru/events-web';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { Row } from 'antd';

const BaseLayout = styled(Row)`
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
`;

const Layout = function () {
  useEffect(() => {
    events.communityLoaded();
  }, []);

  return (
    <BaseLayout>
      <Outlet />
    </BaseLayout>
  );
};

export default Layout;
