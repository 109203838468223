import { useMutation, useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChatState } from '@guuru/constants-common';
import { Form } from 'antd';
import { SendButtonOutlined } from '@guuru/react-icon';
import { LoadingOutlined } from '@ant-design/icons';
import events from '@guuru/events-web';
import { i18n } from '@guuru/translation-web';
import { withTranslation } from 'react-i18next';
import { botMessagesTypes } from '@guuru/chat-web';
import { SearchInput, FormItem } from '../../SearchInput';
import ChatRenderText from '../Text';
import GET_CHAT from './queries/getChatSmsFallback';
import PROVIDE_USER_PHONE from './mutations/provideUserPhone';

const ChatRenderSmsFallback = function ({
  chatId,
  message,
  readOnly = false,
}) {
  const { phone, text } = message;

  const { assigned, created } = ChatState;
  const [phoneNumber, setPhoneNumber] = useState();
  const [currentChatStatus, setCurrentChatStatus] = useState();
  const [form] = Form.useForm();
  const {
    data: {
      ip: {
        ipData: {
          callingCode,
        } = {},
      } = {},
      chat: {
        id,
        status,
      } = {},
    } = {},
    loading,
  } = useQuery(GET_CHAT, {
    variables: {
      id: chatId,
    },
    skip: readOnly,
  });

  useEffect(() => {
    setCurrentChatStatus(status);
  }, [status]);

  const [
    provideUserPhone, {
      error,
      loading: isSaving,
    },
  ] = useMutation(PROVIDE_USER_PHONE);

  const number = phoneNumber || phone;
  const isNumberConfirmed = !isSaving && number;

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      try {
        await provideUserPhone({
          variables: { chatId: id, phoneNumber: values.number },
        });
        events.smsFallback(values.number);
        setPhoneNumber(values.number);
      } catch (err) {
        setPhoneNumber();
      }
    }).catch((err) => err);
  };
  if (isNumberConfirmed) {
    const numberConfirmed = {
      ...message,
      i18n: phone ? text : i18n.t(
        botMessagesTypes.smsFallbackRegistered,
        { smsFallbackPhoneNumber: number },
      ),
    };
    return <ChatRenderText message={numberConfirmed} tooltip={false} />;
  }

  const disabled = readOnly
    || (!isNumberConfirmed && ![assigned, created].includes(currentChatStatus));

  const Icon = isSaving ? LoadingOutlined : SendButtonOutlined;

  const validation = i18n.t('bot:::messageTypeSmsFallbackValidation');
  const placeholder = i18n.t('bot:::messageTypeSmsFallbackPlaceholder');

  return (
    <ChatRenderText message={message} tooltip={false} loading={loading}>
      <Form
        form={form}
        initialValues={{ number: readOnly ? ' ' : `+${callingCode || '41'} ` }}
        className="message-options"
      >
        <FormItem
          name="number"
          help={error && validation}
          rules={[{ required: true, message: validation }]}
          validateStatus={error ? 'error' : undefined}
        >
          <SearchInput
            placeholder={placeholder}
            enterButton={<Icon />}
            onSearch={handleSubmit}
            disabled={disabled || isSaving}
          />
        </FormItem>
      </Form>
    </ChatRenderText>
  );
};

ChatRenderSmsFallback.propTypes = {
  chatId: PropTypes.string.isRequired,
  message: PropTypes.shape({
    metadata: PropTypes.shape({
      placeholderMessage: PropTypes.string,
      validationMessage: PropTypes.string,
      fallbackRegisteredMessage: PropTypes.string,
    }),
    phone: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  readOnly: PropTypes.bool,
};

export default withTranslation()(ChatRenderSmsFallback);
