import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { CommentOutlined } from '@ant-design/icons';
import { GuuruOutlined } from '@guuru/react-icon';

const menuItems = [
  {
    key: 'chats',
    space: 'chat',
    label: 'Messages',
    icon: <CommentOutlined />,
  },
  {
    key: 'guurus',
    space: 'community',
    label: 'Community',
    icon: <GuuruOutlined />,
  },
];

const useMenuItems = (spaces) => {
  const { pathname } = useLocation();

  const selectedKey = useMemo(() => (
    menuItems.find(({ key }) => pathname.includes(key))?.key || 'chats'
  ), [pathname]);

  const mainMenuItems = useMemo(() => (
    spaces.map((id) => menuItems.find(({ space }) => space === id))
  ), [spaces]);

  return { selectedKey, mainMenuItems };
};

export default useMenuItems;
