import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Col, Divider, Flex, Row,
} from 'antd';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { Loading } from '@guuru/react-loading';
import Name from './Name';
import Bio from './Bio';
import Details from './Details';
import Photo from './Photo';
import GET_EXPERT_PROFILE from './queries/getExpertProfile';
import Comments from './Comments';
import Opinions from './Opinions';

const Profile = function () {
  const { expertId, partnerId } = useParams();
  const { search } = useLocation();

  const {
    data: {
      community: {
        expert = {},
      } = {},
    } = {},
    loading,
  } = useQuery(GET_EXPERT_PROFILE, {
    variables: { partnerId, expertId },
  });

  if (loading) {
    return <Loading />;
  }

  if (!expert) {
    return <Navigate to={`../guurus${search}`} />;
  }

  return (
    <Flex wrap justify="center" gap={48}>
      <Col xs={{ flex: '450px' }} md={{ flex: '2' }} lg={{ flex: '450px' }}>
        <Row>
          <Photo expert={expert} />
        </Row>
      </Col>
      <Col xs={24} md={{ flex: '3' }}>
        <Flex vertical gap={24}>
          <Name expert={expert} />
          <Bio expert={expert} />
        </Flex>
        <Divider />
        <Details expert={expert} />
        <Comments expert={expert} />
        <Opinions expert={expert} />
      </Col>
    </Flex>
  );
};

export default Profile;
