import React from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useChatSettings } from '@guuru/chat-web';
import BottomMenu from '../BottomMenu';
import GlobalStyle from '../styles/GlobalStyle';

const excludedPaths = ['/form', '/transcript'];

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const BaseLayout = function () {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const {
    data: {
      chatSettings: {
        chatWidgetFont,
      } = {},
    } = {},
  } = useChatSettings();

  const showBottomMenuInPath = (
    !excludedPaths.some((path) => pathname.includes(path))
  );
  const spaces = searchParams.get('spaces')?.split(',') ?? [];
  const hasMultipleSpaces = spaces.length > 1;

  return (
    <Layout>
      <GlobalStyle font={chatWidgetFont} transparent={false} />
      <Outlet />
      {showBottomMenuInPath && hasMultipleSpaces && (
        <BottomMenu spaces={spaces} />
      )}
    </Layout>
  );
};

export default BaseLayout;
