import styled, { css } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ContactWrapper = styled.span`
  ${({ $showShadow }) => !!$showShadow && css`
    .chat-message {
      box-shadow: rgba(0 0 0 / 10%) 0 4px 15px 0, rgba(0 0 0 / 10%) 0 1px 2px 0, ${({ theme }) => theme.primaryColor} 0 2px 0 0 inset !important;
    }
  `}

  button[type="submit"] {
    width: 220px;
  }

`;
