import React from 'react';
import { Col, Row } from 'antd';
import TotalMembers from './TotalMembers';
import AverageRating from './AverageRating';
import TotalChats from './TotalChats';

const Experts = function () {
  return (
    <Col align="center">
      <Row>
        <Col xs={24} md={8}>
          <TotalMembers />
        </Col>
        <Col xs={24} md={8}>
          <TotalChats />
        </Col>
        <Col xs={24} md={8}>
          <AverageRating />
        </Col>
      </Row>
    </Col>
  );
};

export default Experts;
