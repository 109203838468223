import React from 'react';
import {
  Empty, List, Space, Typography,
} from 'antd';
import { LoadMore } from '@guuru/react-pagination';
import { scaleRating } from '@guuru/rating-common';
import { Rating } from '@guuru/react-rating';
import { HighlightCard } from '@guuru/react-card';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useLocation, useParams } from 'react-router-dom';
import GET_EXPERTS from './queries/getExperts';

const { Title } = Typography;

const Experts = function () {
  const { partnerId } = useParams();
  const { search } = useLocation();

  const {
    data: {
      community: {
        experts: {
          edges: experts = [],
          pageInfo,
        } = {},
      } = {},
    } = {},
    loading,
    fetchMore,
  } = useQuery(GET_EXPERTS, {
    variables: { partnerId, first: 8 },
  });

  const isReady = experts.length > 4;

  return (
    <List
      dataSource={isReady ? experts : []}
      loading={loading}
      locale={{
        emptyText: <Empty
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          image="https://cdn.guuru.com/assets/images/pages/community.png"
          imageStyle={{ height: 'clamp(150px, 30vw, 650px)' }}
          description={(
            <Space direction="vertical">
              <Title level={3}>
                We’re cooking up something amazing...
              </Title>
              <Title type="secondary" level={5}>
                Come back to find a new community here soon
              </Title>
            </Space>
          )}
        />,
      }}
      grid={{
        gutter: [24, 8],
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
        xxl: 4,
      }}
      loadMore={isReady ? (
        <LoadMore
          flex="none"
          data={{ pageInfo }}
          fetchMore={fetchMore}
          loading={loading}
          size="large"
        />
      ) : undefined}
      renderItem={({ node: { id: key, ...expert } }) => (
        <List.Item>
          <HighlightCard
            key={key}
            title={expert.name}
            avatar={expert.photoUrl}
            description={expert.bio}
            url={`${key}${search}`}
            footer={`Member since ${moment(expert.joinedAt).locale('en').fromNow()}`}
            extraTitle={(
              <Rating rating={scaleRating(expert.rating)} hoverable={false} />
              )}
          />
        </List.Item>
      )}
    />
  );
};

export default Experts;
