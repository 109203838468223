import { useMutation } from '@apollo/client';
import { getCurrentUserId } from '@guuru/auth-web';
import { SEND_CHAT_MESSAGE } from '@guuru/graphql-web';

export default ({ chatId, isUser = false }) => {
  const [createNewMessage] = useMutation(SEND_CHAT_MESSAGE);

  const createMessage = async (posting) => {
    const uid = await getCurrentUserId();

    await createNewMessage({
      variables: {
        input: {
          userId: uid,
          chatId,
          isUser,
          isBot: false,
          posting: {
            ...posting,
            author: uid,
            isUser,
            isBot: false,
            hideForUser: false,
            hideForExpert: false,
            data: null,
          },
        },
      },
    });
  };

  const sendMessage = ({ text, type = 'text' }) => (
    createMessage({ text, type })
  );

  const sendAttachment = (readUrl, filename, mimetype) => {
    createMessage({
      address: readUrl,
      filename,
      mimetype,
      type: 'attachment',
    });
  };

  return { sendMessage, sendAttachment };
};
