import { useMutation } from '@apollo/client';
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import { GET_CHAT } from '@guuru/graphql-web';
import Icon from './Icon';
import DELETE_CHAT_MESSAGE from './mutations/deleteChatMessage';

const Delete = function ({ messageId, chatId }) {
  const id = useMemo(() => messageId.split('.')[0], [messageId]);
  const [isOpen, setIsOpen] = useState();

  const [deleteChatMessage] = useMutation(DELETE_CHAT_MESSAGE, {
    refetchQueries: () => [{
      query: GET_CHAT,
      variables: {
        id: chatId,
        isVisibleByExpert: false,
        isVisibleByUser: true,
      },
    }],
  });
  const handleClick = () => deleteChatMessage({ variables: { id } });

  return (
    <Popconfirm
      title="Are you sure you want to delete this message?"
      onConfirm={handleClick}
      okText="Yes"
      cancelText="No"
      onOpenChange={(visible) => setIsOpen(visible)}
    >
      <Icon twoToneColor="#eb2f96" open={isOpen} />
    </Popconfirm>
  );
};

Delete.propTypes = {
  messageId: PropTypes.string.isRequired,
  chatId: PropTypes.string.isRequired,
};

export default Delete;
