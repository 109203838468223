import { useQuery } from '@apollo/client';
import { ChatAPI } from '@guuru/api-web';
import { CHAT_SETTINGS } from '@guuru/graphql-web';

export default (
  partnerId = ChatAPI.storeRetrievePartnerId(),
  category = ChatAPI.storeRetrieveCategoryParameter() || undefined,
  locale = ChatAPI.storeRetrieveLocaleParameter() || undefined,
  token = ChatAPI.storeRetrieveToken() || undefined,
) => {
  const {
    data,
    loading,
  } = useQuery(CHAT_SETTINGS, {
    variables: {
      id: partnerId,
      locale,
      category,
      token,
    },
    skip: !partnerId,
  });
  return { data, loading };
};
