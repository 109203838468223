import React from 'react';
import { Col, Flex, Typography } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const { Text } = Typography;

const QuoteSymbol = styled(Col)`
  &::before {
    font-weight: 800;
    font-size: 55px;
    font-family: "Times New Roman", serif;
    color: #D3D3D3;
    content: "\\201C";
    line-height: 50px;
  }
`;

const Comment = function ({ comment }) {
  return (
    <Flex wrap={false} gap="small">
      <QuoteSymbol />
      <Text italic type="secondary">{comment}</Text>
    </Flex>
  );
};

Comment.propTypes = {
  comment: PropTypes.string.isRequired,
};

export default Comment;
