import { ChatAPI } from '@guuru/api-web';
import { put } from 'redux-saga/effects';
import { types } from '@guuru/chat-web';
import events from '@guuru/events-web';
import { ExpertsPriority } from '@guuru/expert-common';
import createAndTransferChat from '../../chatActions/createAndTransferChat';
import addBotPredictionResult from './addBotPredictionResult';

const isProductAdvise = (automationBehavior) => (
  automationBehavior?.label === 'get_info_product_advise'
);

const hasAlreadyGuurusOnlyPriority = () => {
  const priority = ChatAPI.storeRetrieveChatPriority();
  return (priority === ExpertsPriority.guurusOnly.value);
};

const handleSmartChatRouting = (partnerId, automationBehavior) => {
  let priority = automationBehavior?.subscriptionPriority;
  if (hasAlreadyGuurusOnlyPriority() && isProductAdvise(automationBehavior)) {
    priority = ExpertsPriority.guurusOnly.value;
  }
  ChatAPI.storeChatPriority(priority);
  return { goToNextStep: true };
};

const routeDirectToExperts = function* (subscriptionPriority) {
  ChatAPI.storeChatPriority(subscriptionPriority);
  events.expertFallbackTriggered();
  const liveChat = yield ChatAPI.addBotMessage(
    'bot:::talkToExpertSmartForm',
  );
  yield put({
    type: types.CHAT_NEW_TEXT_SUCCESS,
    message: liveChat,
  });
  return { goToNextStep: true };
};

const routeToExpertsOrTransfer = function* (partnerId, automationBehavior) {
  const liveChatAndTransferOptionsMessage = (
    yield addBotPredictionResult(
      partnerId,
      automationBehavior,
    )
  );
  yield put({
    type: types.CHAT_NEW_TEXT_SUCCESS,
    message: liveChatAndTransferOptionsMessage,
  });
  return { goToNextStep: false };
};

const handleSmartFormRouting = function (partnerId, automationBehavior) {
  if (automationBehavior.id === 'expertFallback') {
    return routeDirectToExperts(automationBehavior?.subscriptionPriority);
  }
  return routeToExpertsOrTransfer(partnerId, automationBehavior);
};

export default function* (automationBehavior, { partnerId, channel }) {
  if (automationBehavior?.subscriptionPriority === 'transfer') {
    yield createAndTransferChat(partnerId, {
      targetId: automationBehavior?.subscriptionTarget,
      handover: automationBehavior.label === 'form_fallback',
    });
    return { goToNextStep: false };
  }

  return (channel === 'form')
    ? yield handleSmartFormRouting(partnerId, automationBehavior)
    : yield handleSmartChatRouting(partnerId, automationBehavior);
}
