import styled from 'styled-components';
import { Button } from 'antd';

const AskAnotherQuestionButton = styled(Button)`
  background-color: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.onPrimaryColor};
  height: 40px;

  &:hover {
    background: ${({ theme }) => theme.onPrimaryColor};
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export default AskAnotherQuestionButton;
