import createAndTransferChat from '../chatActions/createAndTransferChat';

export default {
  validate() { return true; },
  * render(partnerId) {
    yield createAndTransferChat(partnerId, { handover: true });
    return { goToNextStep: false };
  },
  processResponse() {},
};
