import { ChatAPI } from '@guuru/api-web';
import { signInAnonymously, signUpAnonymously } from '@guuru/auth-web';
import { client, CREATE_OR_UPDATE_USER } from '@guuru/graphql-web';
import { getCurrentChatId } from '@guuru/chat-web';

export default async function (partnerId) {
  const params = ChatAPI.storeRetrieveParams();
  const chatId = getCurrentChatId();
  const name = ChatAPI.storeRetrieveName();
  const email = ChatAPI.storeRetrieveEmail();
  const locale = ChatAPI.storeRetrieveLocale();
  const uid = (!chatId && params.token)
    ? await signUpAnonymously()
    : await signInAnonymously();
  const visitor = {
    name,
    email,
    locale: params.payloadUserLocale || locale,
    isUser: ChatAPI.isUser(partnerId),
    isLead: ChatAPI.isLead(partnerId),
  };

  await client.mutate({
    mutation: CREATE_OR_UPDATE_USER,
    variables: {
      uid,
      input: visitor,
    },
  });
  return uid;
}
