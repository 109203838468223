import { ChatAPI } from '@guuru/api-web';
import { heightUpdatedEvent } from '@guuru/chat-events-web';
import { ChatStep } from '@guuru/constants-common';
import { getCurrentChatStep } from '@guuru/chat-web';

let HEIGHT = 0;

const getElement = (selector) => global.document
  .getElementsByClassName(selector)[0];

const getElementHeight = (selector) => (
  getElement(selector)?.scrollHeight || 0
);

const getComponentHeight = () => {
  const headerHeight = getElementHeight('chat-header-wrapper');
  const contentSection = getElementHeight('chat-content-section');
  const askAnotherQuestionHeight = getElementHeight('chat-message-bar');
  return contentSection + headerHeight + askAnotherQuestionHeight + 20;
};

export default function (partnerId, step = getCurrentChatStep()) {
  const channel = ChatAPI.storeRetrieveChannel(partnerId);

  if (channel !== 'form') return;

  let prevHeight = HEIGHT;
  if (step === ChatStep.chatQuestion) {
    HEIGHT = 0;
  } else {
    HEIGHT = getComponentHeight();
  }

  const startTimeout = (timeout) => {
    setTimeout(() => {
      prevHeight = HEIGHT;
      HEIGHT = getComponentHeight();
      if (prevHeight < HEIGHT) {
        heightUpdatedEvent(HEIGHT);
      }
    }, timeout);
  };

  startTimeout(700);
  startTimeout(2000);

  if (prevHeight === HEIGHT) return;
  if (prevHeight < HEIGHT) {
    heightUpdatedEvent(HEIGHT);
  }
}
