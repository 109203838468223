import React from 'react';
import { Avatar } from 'antd';
import { string } from 'prop-types';
import styled from 'styled-components';
import Bot from './bot.svg';

const DefaultAvatar = styled(Bot)`
  color: ${({ theme: { primaryColor } = {} }) => primaryColor ?? 'black'};
  height: 100%;
  width: 100%;
`;

const BotAvatar = React.memo(({ customBotAvatar }) => (
  customBotAvatar
    ? <Avatar shape="square" size={36} src={customBotAvatar} />
    : <DefaultAvatar />
));

BotAvatar.propTypes = {
  customBotAvatar: string,
};

BotAvatar.defaultProps = {
  customBotAvatar: null,
};

export default BotAvatar;
