import React from 'react';
import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';
import { WarningTwoTone } from '@ant-design/icons';
import LanguageFlag from '@guuru/react-language-flag';
import { colors } from '@guuru/constants-common';
import { AvatarGroup } from './style';

const Languages = function ({ locales = {}, maxCount = undefined }) {
  const langs = Array.isArray(locales) ? keyBy(locales) : locales;
  if (Object.keys(langs).length === 0) {
    return (
      <WarningTwoTone twoToneColor={colors.yellow} />
    );
  }

  return (
    <AvatarGroup
      max={{
        count: maxCount,
        style: {
          color: 'rgba(0, 0, 0, 0.45)',
          backgroundColor: 'transparent',
          lineHeight: '25px',
          height: '25px',
        },
        popover: {
          placement: 'topRight',
        },
      }}
    >
      {Object.keys(langs).map((language) => (
        langs[language] && (
        <LanguageFlag
          style={{ paddingRight: 8 }}
          key={`expert_language_${language}`}
          languageCode={language}
        />
        )))}
    </AvatarGroup>
  );
};

Languages.propTypes = {
  locales: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
  ]),
  maxCount: PropTypes.number,
};

export default Languages;
