import React from 'react';
import PropTypes from 'prop-types';
import { useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Row, Space } from 'antd';
import { useChatSettings } from '@guuru/chat-web';
import { ChatAPI } from '@guuru/api-web';
import ChatNPSRate from './ChatNPSRate';
import { GoBackLink, Headline, Text } from './style';
import GET_CHAT from './queries/getUserEmailAndShouldBeContacted';

const ChatThanks = React.memo(({ chatId, readOnly }) => {
  const { t } = useTranslation();
  const locale = ChatAPI.storeRetrieveLocale();

  const {
    data: {
      chatSettings: {
        name: partnerName,
        thankYouSectionShowNps,
        thankYouSectionLink,
      } = {},
    } = {},
  } = useChatSettings();

  const {
    data: { chat: { shouldBeContacted, user } = {} } = {},
  } = useSuspenseQuery(GET_CHAT, { variables: { id: chatId } });

  const i18nData = {
    partnerName,
    email: user?.email,
  };

  const showActionLink = thankYouSectionLink?.[locale];
  const showNPSSection = !!(thankYouSectionShowNps);
  return (
    <Row justify="center">
      <Space direction="vertical">
        <Headline>
          {t('chat:::finishedThankYouHeadline', i18nData)}
        </Headline>
        {shouldBeContacted && (
          <Text>
            {t('chat:::rateExpertBadEndContact', i18nData)}
          </Text>
        )}
        {showActionLink && (
          <GoBackLink href={thankYouSectionLink[locale]} target="_blank">
            {t('chat:::finishedGoBackToPartner', i18nData)}
          </GoBackLink>
        )}
        {!readOnly && showNPSSection && chatId && (
          <ChatNPSRate chatId={chatId} />
        )}
      </Space>
    </Row>
  );
});

ChatThanks.propTypes = {
  chatId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

ChatThanks.defaultProps = {
  readOnly: false,
};
export default ChatThanks;
