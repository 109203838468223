import React from 'react';
import {
  Col, Typography, Flex, Divider,
} from 'antd';
import PropTypes from 'prop-types';
import OpinionsList from './OpinionsList';

const { Title } = Typography;

const Opinions = function ({ expert }) {
  const { mentions } = expert;

  return !!mentions?.length && (
    <Flex wrap justify="center" gap="large">
      <Divider />
      <Col span={24}>
        <Title level={4}>Latest Opinions from Conversations</Title>
      </Col>
      <Col span={24}>
        <OpinionsList mentions={mentions} />
      </Col>
    </Flex>
  );
};

Opinions.propTypes = {
  expert: PropTypes.shape({
    mentions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default Opinions;
