import React from 'react';
import { useLocation } from 'react-router-dom';
import Profile from '../../components/Expert';
import CommunityView from '../../containers/Container';

const Expert = function () {
  const { pathname, search } = useLocation();
  const parent = `${pathname.substring(0, pathname.lastIndexOf('/'))}${search}`;
  return (
    <CommunityView goBackToPath={parent}>
      <Profile />
    </CommunityView>
  );
};

export default Expert;
