import { gql } from '@apollo/client';
import { SUBSCRIPTION_FRAGMENT } from '@guuru/graphql-web';

export default gql`
  ${SUBSCRIPTION_FRAGMENT}
  query GetPrediction(
    $partnerId: ID!,
    $question: String!,
    $languageCode: String!,
    $channel: String,
    $params: JSON,
  ) {
    partner(id: $partnerId) {
      id
      predict(
        question: $question,
        languageCode: $languageCode,
        channel: $channel,
        params: $params
      ) {
        intentSubscription {
          ...IntentSubscription
        }
        proposedIntent {
          id
          label
          score
          minimumPrecision
        }
        possibleIntents {
          ...IntentSubscription
        }
      }
    }
  }
`;
