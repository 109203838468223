import { gql } from '@apollo/client';
import { CHAT_MESSAGES_FRAGMENT } from '@guuru/graphql-web';

const GET_CHAT_MESSAGES = gql`
  ${CHAT_MESSAGES_FRAGMENT}
  query GetChatMessages(
    $id: ID!,
    $isVisibleByExpert: Boolean!,
    $isVisibleByUser: Boolean!,
  ) {
    chat(id: $id) {
      id
      rating
      status
      acceptedAt
      partner {
        id
        settings {
          id
          appearance {
            isUnreadMessagesEnabled
          }
        }
      }
      expert {
        id
        name
      }
      ...MessagesFragment
    }
  }
`;

export default GET_CHAT_MESSAGES;
