import React from 'react';
import { Alert, Typography } from 'antd';
import { ChatAPI } from '@guuru/api-web';
import { useChatSettings } from '@guuru/chat-web';
import styled from 'styled-components';
import Linkify from '@guuru/react-linkify';

const { Text } = Typography;

const ColoredAlert = styled(Alert)`
  a {
    color: ${({ theme }) => theme.onBackgroundColor};
    text-decoration: underline;
  }
`;

const ChatAlert = function () {
  const locale = ChatAPI.storeRetrieveLocale();
  const {
    loading,
    data: {
      chatSettings: {
        alert,
      } = {},
    } = {},
  } = useChatSettings();

  if (loading || !alert?.id) return null;

  return (
    <ColoredAlert
      banner
      closable
      message={<Text strong>{alert?.title[locale]}</Text>}
      description={(
        <Linkify
          text={alert?.message[locale]}
          supportMarkdown={false}
        />
      )}
      showIcon={false}
      style={{ paddingBlock: 10 }}
    />
  );
};
export default ChatAlert;
