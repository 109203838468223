import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${({ font }) => font && font !== 'system-ui' && (
    `@font-face {
      font-family: PartnerFont;
      src: ${/^https?:\/\//.test(font) ? `url(${font})` : `local(${font})`};
    }`
  )}

  html,
  body,
  .app,
  #app,
  main {
    height: 100%;
  }

  html {
    ${({ font }) => font === 'system-ui' && (`
      @supports (font: -apple-system-body) and (-webkit-touch-callout: default) {
        font: -apple-system-body;
      }
    `)}
    background-color: ${({ theme, transparent }) => (transparent ? 'inherit' : theme.backgroundColor)};
    ${({ theme, transparent }) => theme.backgroundUrl && !transparent && (
    `background-size: cover;
     background: url(${theme.backgroundUrl});`
  )}
  }
`;

export default GlobalStyle;
